import * as React from 'react'
import styled from 'styled-components'
import { breakpoint, colors } from '../styles/variables'


const CardWrap = styled.div`
  padding: 1rem 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.darkBackground};
  height: 100%;
  text-align: center;
`

const StyledIcon = styled.span`
    font-size: 80px;
    color: ${colors.primary};
    border: 1px solid ${colors.primary};
    padding: 0.75rem;
    border-radius: 50%;

    @media (min-width: 412px) {
      font-size: 60px;
    }

    @media ${breakpoint.lg} {
      font-size: 80px;
    }
`

const CardTitle = styled.h3`
  margin: 0;
  display: grid;
  align-items: center;
  min-height: 4rem;
  font-weight: 500;
  color: #fff;
`

const CardText = styled.p`
  font-size: 0.875rem;
  color: white;
  margin-top: 1rem;
  font-size: 0.875rem;

  @media ${breakpoint.lg} {
    font-size: 1rem;
  }
`

const ServicePrice = styled.p`
  margin-top: auto;
  margin-bottom: 0;
  font-weight: 500;
  background-color: #f49a4159;
  padding: 0.5em 1em;
  width: 100%;
  color: #fff;

`

const Card = ({ title, subtitle, duration, price, icon, bgImage}) =>{
    return  (

        <CardWrap>

                {!bgImage && <StyledIcon className={icon} ></StyledIcon> }
                <CardTitle>{title}</CardTitle>
                <StyledIcon className={icon} ></StyledIcon>
                <CardText>{duration}</CardText>
                <ServicePrice>{price}</ServicePrice>
                {/* <GetService to='/contact'>Poptat</GetService> */}

        </CardWrap>
    )
}

export default Card

