export const places = {
    austria: {
        title: "Rakousko",
        description: "Neplánujete zamířit daleko za hranice, ale raději byste navštívili jenom sousedy? Rakousko nabízí nádherné přírodní scenérie i vynikající pivovary, které nabídnou vynikající orosenou odměnu. Třeba túry na vrcholy jako Kleiner i Großer Schoberstein zvládne i začátečník. Odtud se pak můžete kochat výhledem rovnou na tři nádherná jezera v okolí.",
        image: "",
    },
    bulgaria: {
        title: "Bulharsko",
        description: "Projít Bulharsko očima zdejších. Přesně to vám nabízím. Zejména Sofia je má srdcová záležitost, půl roku jsem zde žila a pracovala. Fantastické kafe z namletých zrn za 15-20 korun v každé trafice. I to je Bulharsko. Poznejte ho jinak než ostatní.",
        image: "",
    },
    cambodia: {
        title: "Kambodža",
        description: "Plánujete zavítat do exotických krajin? Kambodža se vám nabízí! Doslova mě učarovala a věřím, že s mou pomocí vás okouzlí také. Kontaktujte mě a vymysleme itinerář na míru.",
        image: "",
    },
    canaryIslands: {
        title: "Kanárské ostrovy",
        description: "Kanárské ostrovy jsou skvělou destinací, ať už sem chcete zavítat na jaro nebo na podzim. Počasí je totiž poměrně stabilní, jen v zimě zde teploty spadnou ke dvaceti stupňům.",
        image: "",
    },
    chania: {
        title: "Chania",
        description: "Až budete ubytovaní v Chanii, zajeďte si do místní taverny Kafe-mezedopoleio Ta miglakia. Není příliš známá, nachází se v malé horské vesničce. O to kouzelnější zážitek to bude, to mi můžete věřit.",
        image: "",
    },
    crete: {
        title: "Kréta",
        description: "Kréta je nejjižnějším řeckým ostrovem, který rozhodně má, co nabídnout. Podle zdejších se zde nachází nejkrásnější středomořská pláž i nejdelší soutěska v Evropě. Destinaci můžete vidět z mnoha úhlů. Doporučuju navštívit hlavně krásné město Chania, kde se ukrývá celá spousta báječných podniků jedním z nich je například rybárna Thalassino Ageri. Je skvělá!",
        image: "",
    },
    croatia: {
        title: "Chorvatsko",
        description: "Dovolená v Chorvatsku je mezi Čechy taková klasika. Ukážu vám, že ji lze prožít jinak, než jak jste ji prožili doposud. Rády vám sestavíme itinerář i do této oblíbené destinace.",
        image: "",
    },
    cuba: {
        title: "Kuba",
        description: "Země, kde je rum levnější než voda. Největší karibský ostrov, jehož bílé písečné pláže omývá karibské moře, stará americká auta, nejvyhlášenější kubánské doutníky a místní tančící v ulicích při každé příležitosti, tohle vše můžete zažít při návštěvě Kuby. Málokdy se vracím na stejná místa dvakrát, ale Kuba je má srdcová záležitost, místo, kam rozhodně ráda znovu vyrazím.",
        image: "",
    },
    czechia: {
        title: "ČR",
        description: "Zdolat nejvyšší vrchol Česka, projít si starou Prahu nebo objet nejkrásnější hrady a zámky, které tuzemsko nabízí. Pravá česká dovolená může mít mnoho podob. Pojďme najít tu, která vám bude šitá na míru.",
        image: "",
    },
    denmark: {
        title: "Dánsko",
        description: "Naprosto jiný, daleko vyspělejší svět. Asi tak bych popsala Dánsko, kde se budete projíždět na kolech, obdivovat jeden upravený dům vedle druhého a popíjet pivo, které bude tou nejlepší orosenou odměnou po maximálně prožitém dni!",
        image: "",
    },
    england: {
        title: "Anglie",
        description: "Big Ben, Buckinghamský palác nebo London Eye - to vše jsou špendlíky na mapě každého londýnského turisty. Ukážu vám, že Londýn není jenom o tom.",
        image: "",
    },
    france: {
        title: "Francie",
        description: "Francie patří k nejromantičtějším zemím na světě. Vedle Eiffelovky je dalším špendlíkem na mapě Louvre, vedle kterého se nachází nádherná prodejna čokolády a pralinek jménem Jean-Paul Hévin Chocolatier Pâtissier."
    },
    germany: {
        title: "Německo",
        description: "Německo není jenom o schnitzelu a pivu. Německo nabízí řadu krásných míst, a to nejen památky, ale i přírodní scenérie, do nichž se zamilujete na první pohled.",
        image: "",
    },
    holand: {
        title: "Holandsko",
        description: "Všude rostoucí a vonící tulipány, větrné mlýny a kola široko daleko, kam se podíváte. Přesně tak lze definovat Holandsko. I když je, podle mě, nejhezčí na jaro, i v jiných ročních obdobích dokáže uchvátit."
    },
    hungary: {
        title: "Maďarsko",
        description: "Maďarsko je země lázní a dobrého vína, protkaná stovkami kilometrů cyklistických tras. Zavítejte do kraje papriky, která se zde pěstuje, ochutnejte maďarský guláš, vykoupejte se na Balatonu, největším jezeře ve střední Evropě. Pokud chcete zažít trošku vzrušení a kultury, vyražte v srpnu na sedmidenní Sziget festival, jeden z největších hudebních festivalů v Evropě už od roku 1993.",
        image: "",
    },
    italy: {
        title: "Itálie",
        description: "Země sluncem, jídlem a vínem zalitá. Přesně tak bych popsala Itálii, která je v hledáčku mnoha vášnivých turistů. Důvod je prostý - letenky jsou poměrně levné, necestuje se dlouho a moře je, co by kamenem dohodil. Romantické Benátky, starověký Řím nebo Toskánsko. Ať už se rozhodnete pro poznávací dovolenou nebo dáte přednost té odpočinkové, bude to s mou pomocí stát za to.",
        image: "",
    },
    israel: {
        title: "Izrael",
        description: "Do Izraele se jezdí hlavně ke zdejšímu Mrtvému moři. Až se budete v těchto místech nacházet, doporučuju zavítat i do místního podniku Taj Mahal, kde podávají vynikající vegetariánské speciality. Zároveň Vám povím, kam na nejtradičnější hummus v Jeruzalémě.",
        image: "",
    },
    jordan: {
        title: "Jordánsko",
        description: "Jordánsko je známé zejména proto, že jeho součástí je jeden ze sedmi novodobých divů světa, skalní město Petra. Ale může nabídnout mnoho dalšího, jako jsou dobrodružné výlety jeepy do nejbarevnější pouště Wadi Rum, která místy připomíná krajinu Marsu nebo odpočinek v Mrtvém moři. Na cestách určitě doporučujeme vyzkoušet beduínský mansaf, čaj a kávu.",
        image: "",
    },
    latvia: {
        title: "Lotyšsko",
        description: "Lotyšsko, a především Riga, je má srdcová záležitost! Jedno z nejkrásnějších míst, kterým vás ráda provedu. Vím, co stojí za vidění a kde se dobře najíst, aby se chuťové pohárky na jazyku doslova zbláznily radostí. Central market, jeden z největších marketů ve východní Evropě, i Siļķītes un Dillīte, bistro, kam se budete velice rádi vracet. I to nabízí úchvatné Lotyšsko.",
        image: "",
    },
    lithuania: {
        title: "Litva",
        description: "Největší pobaltský stát, který Vám nabídne mnohem více, než čekáte - unikátní hrad Trakai - jako symbol litevské samostatnosti, Hora křížů - nejmystičtější místo v Litvě, památky UNESCO, Kurská kosa a písečné duny na místě, kde bychom je nečekali a navíc rozmanitá kuchyně. Toto vše v jedné jediné zemi.",
        image: "",
    },
    morocco: {
        title: "Maroko",
        description: "Země krásných kontrastů, kde jeden den můžete stát na zasněžených vršcích Vysokého Atlasu a následující můžete surfovat na vlnách Atlantického oceánu nebo pozorovat hvězdy na dunách Sahary. Ochutnejte typické marocké jídlo tažín a ztraťte se v uličkách mediny ve Fezu.",
        image: "",
    },
    northernIreland: {
        title: "Severní Irsko",
        description: "Na Severní Irsko vám bude stačit pár dní, jedná se o jednu ze čtyř zemí Spojeného království. Adrenalinový přechod po visutém, hýbajícím se mostku ve výšce 30 metrů nad vlnami pokračující přes Giant's causeway, odkud dojdete až k hradu Dunluce je  krásnou celodenní procházkou.",
        image: "",
    },
    northMacedonia: {
        title: "Severní Makedonie",
        description: "Liduprázdné hory, bohatá historie a vynikající balkánská kuchyně - tímto vším vás očaruje Severní Makedonie. Rodné město Svaté Matky Terezy vás uhrané svou (někdy pro někoho až kýčovitou) architekturou a nezapomenutelnou atmosférou jedno z nejstarších tržišť na Balkáně.",
        image: "",
    },
    norway: {
        title: "Norsko",
        description: "Norsko můžete vidět z mnoha úhlů. Buďto si naplánujte cestu ke zdejším přírodním krásám, nebo se jen budete procházet uličkami obehnanými barevnými domy. Tak či onak, bude to zážitek na celý život.",
        image: "",
    },
    poland: {
        title: "Polsko",
        description: "Že Polsko nemá, co nabídnout? Ale kdeže! Má! A je toho tolik. Pokud chcete strávit prodloužený víkend pouze za hranicemi tuzemska, sestavím vám itinerář na míru, s nímž poznáte sousední Polsko z jiného úhlu.",
        image: "",
    },
    rhodes: {
        title: "Rhodos",
        description: "Zamilovat si Řecko je tak snadné! Přesvědčte se o tom sami. Itinerář vyplníme nejen zastávkami u moře, ale i procházkami zdejšími uličkami, které se vám doslova vryjí pod kůži.",
        image: "",
    },
    scotland: {
        title: "Skotsko",
        description: "Loch Ness, Edinburgh nebo Ben Nevis, ať už Vám jsou tyto názvy známé nebo ne tohle a mnohem víc Vám může nabídnout Skotsko. Země rozkládající se mimo jiné na 790 ostrovech, země jezer, zelených kopců a whisky. Tak co, vyrazíte s námi prozkoumat krásy této země? Ale nezapomeňte, nyní už s sebou musíte přibalit cestovní pas!",
        image: "",
    },
    serbia: {
        title: "Srbsko",
        description: "Srbsko Vás nadchne svou nádhernou krajinou, vynikající gastronomií a milými lidmi. Poradím vám, jak zde strávit volný víkend i týdenní dovolenou.",
        image: "",
    },
    slovakia: {
        title: "Slovensko",
        description: "Tak blízko a pro některé přitom tak daleko. I na návštěvu Slovenska můžete vyrazit na dva dny nebo týden a pořád budete mít co objevovat. Věděli jste, že nejmenší vesnice na Slovensku má pouze 12 obyvatel? Výhodou dovolené na Slovensku je, sem dorazíte za pár hodin, nepotřebujete cizí jazyk a můžete si vybrat zda se chcete toulat po horách nebo odpočívat v termálech.",
        image: "",
    },
    spain: {
        title: "Španělsko",
        description: "Nádherné písečné pláže, šumějící moře, ale také dechberoucí památky a architektonické skvosty. Přesně to vám nabízí Španělsko. Pomůžu vám naplánovat cestu k vaší spokojenosti.",
        image: "",
    },
    sriLanka: {
        title: "Sri Lanka",
        description: "Hory, moře, nádherná příroda, úžasní lidé a rozmanitá kuchyně - to je Sri Lanka. Ochutnejte zde cejlonský čaj, projděte se po plantážích, ochutnejte místní curry, zkuste lekci surfování, seznamte se kulturou místních a navštivte Safari. Pomocí mého itineráře si zamilujete Sri Lanku stejně, jako já. ",
        image: "",
    },
    thailand: {
        title: "Thajsko",
        description: "Exotika jak se patří. Přesně tak bych popsala Thajsko, které je zcela jiným světem, než jaký nás běžně obklopuje. Plánujete cestu právě sem? Upusťte od běžných starostí týkajících se ubytování a cestou z letiště zaměřte svou pozornost pouze na místa, která chcete navštívit. Detaily jako hledání ubytování, způsoby přepravy a zajímavá místa vám vyhledám a doporučím já.",
        image: "",
    },
    turkey: {
        title: "Turecko",
        description: "V Turecku si můžete vybrat, zda strávíte odpočinkovou dovolenou v několika hvězdičkovém hotelu s tureckými lázněmi nebo jej procestujete na vlastní pěst. Země rozprostírající se na dvou kontinentech Evropy a Asie nabízí množství historických, přírodních a kulturních památek.",
        image: "",
    },
    ukraine: {
        title: "Ukrajina",
        description: "Naplánuju vám dovolenou v mé rodné Ukrajině. Poradím, kde se dobře najíst, co vidět a prozradím, jak to tady chodí. Ukrajina umí i nadchnout. Dejte jí šanci a poznejte zcela jiný kraj, který velmi dobře znám.",
        image: "",
    },
    usaEast: {
        title: "USA - východní pobřeží",
        description: "Skvělá volba pro milovníky velkých měst, muzeí, památek, galerií, knihoven a výstav. Boston - studentské město, Philly - zde se psala historie, DC - hlavní město se spoustou muzeí a New York - to je zkrátka New York, ten musíte zažít.",
        image: "",
    },
    wales: {
        title: "Wales",
        description: "Cestu do Walesu považuji za cestování časem. Najednou vás obklopí starověké vykopávky, hrady, zámky i hornická města, která se jen tak někde nevidí. Historie na vlastní oči, řekla bych.",
        image: "",
    },
    portugal: {
        title: "Portugalsko",
        description: "Prosluněné Algarve, pohádkové Porto, portské víno, tapas, turistické a poutnické trasy. Nejen to a mnohem více vám nabízí Portugalsko. Každá část této země je v něčem jiná a každá má své kouzlo. Chcete ho poznat?",
        image: "",
    },
    belgium: {
        title: "Belgie",
        description: "Věděli jste, že hlavní město Brusel není zdaleka největší, jak to bývá zvykem u hlavních měst? Tak teď už to víte. A můžete se toho dozvědět mnohem více. Třeba kam zajít na ochutnávku piv v Bruggách, nebo kde si výborné belgické hranolky, a čokoládě ani nemluvím. To a mnoho dalšího vám nabídne Belgie.",
        image: "",
    },
    spain2: {
        title: "Španělsko",
        description: "Španělsko není jenom o Barceloně, Madridu a Valencii. Určitě stojí za navštívení i jiná místa - třeba Galicia (o které uslyšíte, že není Španělsko). Za mě jedna z mých oblíbených Evropských destinací. Domov Gaudího a místo poutníků.",
        image: "",
    }
}

export const bundles = [
    {
        title: 'Víkend',
        icon: 'icon icon-weekend',
        duration: '3-4 dny',
        price: '1200-1600,- Kč',
    },
    {
        title: 'Prodloužený víkend',
        icon: 'icon icon-weekend_xl',
        duration: '5-7 dní',
        price: '1800-2300,- Kč',
    },
    {
        title: 'Dovolená',
        icon: 'icon icon-vacation',
        duration: '8-14 dní',
        price: '2500-3800,- Kč',
    },
    {
        title: 'Pořádná dovolená',
        icon: 'icon icon-vacation_xl',
        price: '4200-6500,- Kč',
    }
]

export const bundleIncludes = [
    {
        icon: 'icon icon-info',
        text: 'souhrn toho, co zařídit před cestou (víza, očkování, testy, peníze)'
    },
    {
        icon: 'icon icon-accommodation',
        text: '3x možnosti ubytování dle lokality, preferencí a vašeho budgetu'
    },
    {
        icon: 'icon icon-transport',
        text: 'doporučení přesunu z letiště na hotel a zpět (půjčení auta, veřejná doprava, vlastní auto)'
    },
    {
        icon: 'icon icon-tickets',
        text: '3x možnosti nejvýhodnějších letenek'
    },
    {
        icon: 'icon icon-gastronomy',
        text: 'gastronomické tipy (alespoň jeden návrh na den, kde se dobře najíst)'
    },
    {
        icon: 'icon icon-culture',
        text: 'aktivity – co navštívit (zajímavá místa, památky, kde koupit vstupenky, otevírací doba, cena vstupu apod.)'
    }
]

export const services = [
    {
      title: 'Gastronomická doporučení',
      subtitle: '(restaurace, bistra, kavárny)',
      icon: 'icon-gastronomy',
      content: `Hlavně se dobře najíst a poznat kulturu prostřednictvím místních specialit. To je přání mnoha turistů v cizích zemích. Jenže najít ideální bistro, kde dobře vaří typické pokrmy daného státu, navíc za sympatické ceny je trochu oříšek. Nechte to na mě, vím, kam zajít.`,
      price: '120,-'

    },
    {
      title: 'Dopravní služby',
      subtitle: '(zapůjčení auta, MHD, ferry, vlaky)',
      icon: 'icon-transport',
      content: `Na letištích vládne občas panika. Nenechte se jí strhnout. Zjistím vám, jak se z letiště dostat na hotel či jinam, a navrhnu nejlepší možné řešení.`,
      price: '250,-'
    },
    {
      title: 'Doporučení letenek',
      icon: 'icon-tickets',
      content: `Letenky bývají nejdražší položkou z celého zájezdu. Není to ale pravidlo vytesané do kamene. Najdu vám takové, které nepřekročí vámi stanovený budget.`,
      price: '250,-'
    },
    {
      title: 'Doporučení ubytování',
      icon: 'icon-accommodation',
      content: `Vybrat si adekvátní ubytování může být trochu oříšek. Vyhněte se hodinám stráveným hledáním vhodného hotelu a přenechte to mně. Ráda vám najdu vícero možností na základě vašeho budgetu i počtu cestujících.`,
      price: '250,-'
    },
    {
      title: 'Info před cestou',
      subtitle: '(víza, pasy, peníze, covid info)',
      icon: 'icon-info',
      content: `Budou potřeba víza? A musím se před cestou naočkovat? Zjistím vám informace týkající se aktuální cestovní situace, která se, zejména nyní, liší stát od státu`,
      price: '300,-'

    },
    {
      title: 'Doporučení vhodných aplikací na cestu / pro danou lokalitu',
      icon: 'icon-app',
      content: `Říkali jste si někdy, že byste potřebovali pomoct? Třeba při shánění taxíku ve městě nebo při objednávání jídla? Podělím se s vámi o aplikace, které vám při cestování v dané lokalitě budou při ruce`,
      price: '200,-'
    },
    {
      title: 'Doporučení aktivit ve městě',
      subtitle: '(muzea, galerie, parky, památky)',
      icon: 'icon-culture',
      content: `Rádi poznáváte místní kulturu skrze muzea, galerie a místní památky, ale nebaví vás vyhledávat, co opravdu stojí za to - jak se vyhnout frontám na jejich vstupenky, jaká je otevírací doba, jaké dny jsou pro návštěvu nejlepší? Nevadí, nechte to na mě - a navíc vám zjistím, kdy je možné například nějaká ta místa navštívit zdarma a za ušetřené peníze si dopřát dobrou kávu.`,
      price: '120,-',
    },
    {
      title: 'Doporučení aktivit v přírodě',
      subtitle: '(treky, trasy, zajímavá místa, hory, vodopády, jezera)',
      icon: 'icon-nature',
      content: `Hory, vodopády, jezera - dáváte přednost dovolené v přírodě a zajímá vás, jak fungují turistické trasy v dané zemi, jaká jsou tam značení, je možné volně kempovat nebo kde se vykoupat po náročné túře? Ráda vám poradím nebo vyhledám, jaké možnosti vám daná země/lokalita nabízí.`,
      price: '120,-'
    },
    {
      title: 'Doporučení kulturních akcí',
      subtitle: '(trhy, festivaly, markety v dané lokalitě)',
      icon: 'icon-entertainment',
      content: `Chcete si užít dovolenou během jedinečného festivalu nebo podobné akce? Toužíte zažít Itálii nebo Španělsko jinak? Vím, kde se co šustne, a sepíšu vám kulturní akce, kterých stojí za to se zúčastnit.`,
      price: '120,-'
    },
    {
      title: 'Individuální konzultace',
      subtitle: 'až 2 hod (osobně v Praze nebo online)',
      icon: 'icon-consultation',
      content: `Máte před sebou dovolenou, ale rádi byste tipy, kam zajít nebo kde se ubytovat? Či vás zajímají jiné informace týkající se destinace, ve které jsem sama byla? Pojďme se sejít v rámci individuálních konzultací. Na všechno vám ráda odpovím.`,
      price: '1000,-'
    },
  ];