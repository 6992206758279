import * as React from 'react'
import Container from '../components/Container'
import SmallBanner from '../components/SmallBanner'
import styled from 'styled-components'
import { breakpoint, colors } from '../styles/variables'
import Accordion from '../components/Accordion'
import { services } from "../content/content"
import ServiceBundles from '../organisms/ServiceBundles'
import { PageContent } from '../components/PageContent'
import { Link } from 'gatsby'
import { PageHead } from '../components/PageHead'

const IndividualServices = styled.ul`
  display: grid;
  gap: 0.5rem;
  flex-grow: 1;

  @media ${breakpoint.md} {
    order: 3;
  }

  @media ${breakpoint.md} {
    padding: 1rem 0;
  }
`

const StyledSection = styled.section`
  background-color: ${colors.darkBackground};
  padding: 2rem 0;
  color: #fff;
  margin-top: 4rem;
  padding: 3rem 0 4rem;

  @media ${breakpoint.sm} {
    margin-top: 8rem;
    padding-bottom: 5rem;
  }
`

const StyledSectionTitle = styled.h2 `
  color: ${colors.primary};
  margin-top: 0;

  @media ${breakpoint.md} {
    order: 1;
    margin: 0;
    width: 50%;
  }
`

const CtaLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: #fff;
  text-align: center;
  padding: 1em 1.5em;
  border: 0.5px solid #fff;
  transition: all 0.3s ease;
  //border-radius: 2px;
  cursor: pointer;
  font-size: 0.875rem;
  margin-top: 3rem;
  width: 100%;

  @media ${breakpoint.md} {
    order: 2;
    width: initial;
    margin-top: 0;
  }

  &:hover {
    border-color: ${colors.primary};
    color: ${colors.primary};
  }
`

const WrapWithCta = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5em;

  @media ${breakpoint.md} {
    gap: 1.5em 0;
  }
`

const ServicesPage = ({title}) => {

   return (
    <>
      <SmallBanner title="Služby">
      </SmallBanner>
      <PageContent>
        {/* <StyledContainer> */}
        <Container><ServiceBundles/></Container>
          <StyledSection>
            <Container>
              <WrapWithCta>
                <StyledSectionTitle>Samostatné služby</StyledSectionTitle>
                <IndividualServices className="accordion">
                      {services.map(({ index, title, subtitle, icon, content, price }) =>
                          <li key={index}>
                            <Accordion title={title} content={content} subtitle={subtitle} icon={icon} price={price} />
                          </li>
                    )}
                  </IndividualServices>
                  <CtaLink to="/kontakt">Poptat samostatné služby</CtaLink>
              </WrapWithCta>
            </Container>
          </StyledSection>
         {/*  <ServicesGrid>
          {services.map(({ index, title, subtitle, content, price, icon }) =>
                  <li key={index}>
                    <Card title={title} subtitle={subtitle} duration={content} icon={icon} price={price} />
                  </li>
            )}
          </ServicesGrid> */}
        {/* </StyledContainer> */}
      </PageContent>
    </>
  )
}

export default ServicesPage

export const Head = () => (
  <>
    <PageHead title="Služby | Cestu-j"/>
  </>
)