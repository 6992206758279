import * as React from 'react'
import styled from 'styled-components'
import { useState } from 'react'
import { colors } from '../styles/variables'


const AccordionWrapper = styled.div`
  padding: 0.875rem 0;
  display: grid;
  width: 100%;
  border-bottom: 1px solid #00000059;
`

const AccordionButton = styled.button`
  width: 100%;
  background-color: transparent;
  border: none;
  display: grid;
  grid-template-columns: 2rem 1fr 2.5rem 1rem;
  text-align: left;
  gap: 0 1rem;
  cursor: pointer;
  transition: color 0.3s ease;
  padding: 0;
  color: #fff;
  line-height: 1.4;
  position: relative;
  align-items: center;;


  &:hover {
    color: ${colors.primary};
  }

  .icon-expand, .icon-shrink {

    color: silver;
  }
`

const AccordionContent = styled.p`
  color: #bcbcbc;
`

const AccordionContentWrap = styled.div`

  p {
    padding: 0.5rem 5.5rem 0.5rem 3rem;
    display: none;
  }


  &.show {

    p {
      margin: 0;
      opacity: 1;
      padding: 0.5rem 5.5rem 0.5rem 3rem;
      height: auto;
      display: block;
      margin-top: 0.5em;
    }
  }
`


const Title = styled.h3`
  margin: 0;
  font-size: 1rem;
`
const Price = styled.span`
  margin: 0;
  font-size: 1rem;
`

const SubTitle = styled.p`
  margin: 0.2em 0 0;
  font-size: 0.875rem;
  font-weight: 300;
  color: #bcbcbc;
`

const AccordionTitle = styled.div`
  justify-self: start;
`

const StyledIcon = styled.div`
  font-size: 2rem;
  position:relative;
  top: -4px;
  height: 2rem;
`

const Accordion = ( { title, subtitle, icon, content, price }) => {
  const [isActive, setIsActive] = useState(false);

    return (
      <AccordionWrapper className="accordion-item">
        <AccordionButton className="accordion-title" onClick={() => setIsActive(!isActive)}>
          <StyledIcon><span className={icon}></span></StyledIcon>
          <AccordionTitle>
            <Title>{title}</Title>
            <SubTitle>{subtitle}</SubTitle>
          </AccordionTitle>
            <Price>{price}</Price>
            <span className={isActive ? "icon-shrink" : "icon-expand"}></span>
        </AccordionButton>
        <AccordionContentWrap className={isActive ? "show" : ""}>
          <AccordionContent className="accordion-content">{content}</AccordionContent>
        </AccordionContentWrap>
    </AccordionWrapper>
    )
}

export default Accordion