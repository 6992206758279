import * as React from 'react'
import styled from 'styled-components'
import { breakpoint, colors } from '../styles/variables'
import { Link } from 'gatsby'
import Card from '../components/Card'
import { bundles, bundleIncludes } from '../content/content'


const Wrapper = styled.section`
  `

const SectionTitle = styled.h2 `
  margin: 0;

  @media ${breakpoint.md} {
    order: 1;
  }
`
const Perex = styled.p`
  margin-bottom: 2em;

  @media ${breakpoint.md} {
    order: 3;
    margin: 0;
  }
`

const Bundles = styled.ul`
 display: grid;
 gap: 1rem;

 @media (min-width: 412px) {
 grid-template-columns: repeat(2, 1fr);
 }

 @media ${breakpoint.md} {
  grid-template-columns: repeat(4, 1fr);
  margin: 4rem 0;

  @media ${breakpoint.lg} {
    gap: 1.25rem;
  }
}
`

const BundleList = styled.ul`
  display: grid;
  font-size: 0.875rem;

  @media ${breakpoint.md} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1em;
    margin-bottom: 2rem;
  }
`

const BundleListItem = styled.li`
  position: relative;
  margin: 0.5em 0;

  .icon {
    position: absolute;
    font-size: 2em;
    top: 0;
    left: 0;
  }

  p {
      margin: 0;
  }
`

const BundleListText = styled.p`
  padding-left: 3em;
`

const BundleInfo = styled.div`
  padding: 0 1rem;

  @media ${breakpoint.md} {
    padding: 0 2rem;
  }
`

const BundleNote = styled.p`
  font-weight: 500;
`

const DescriptionTitle = styled.h3`
  font-size: 1rem;
  margin-top: 2rem;
  //font-weight: 700;

  @media ${breakpoint.sm} {
    margin-top: 3rem;
  }
`

const Instructions = styled.p`
  font-weight: 500;
  margin-top: 3rem;
  padding: 0 1em;
  text-align: center;

  @media ${breakpoint.md} {
    font-size: 1.125rem;
    text-align: left;
    padding: 0;
  }

  a {
    color: ${colors.main};
  }

`
const CtaLink = styled.a`
  display: block;
  text-decoration: none;
  border: 2px solid #000;
  color: #000;
  text-align: center;
  padding: 1em 1.5em;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 0.875em;
  font-weight: 500;
  width: 100%;

  @media ${breakpoint.md} {
    order: 2;
    width: initial;
  }

  &:hover {
    border-color: ${colors.primary};
    color: ${colors.primary};
  }
`
const TitleWithCta = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5em;

  @media ${breakpoint.md} {
    gap: 1.5em 0;
    margin-bottom: 0;
  }
`

const ServiceBundles = () => {
    return (
    <Wrapper>

          <TitleWithCta>
            <SectionTitle>Itinerář na míru</SectionTitle>
            <Perex>Připravím vám itinerář na míru, který bude plný ingrediencí vytvářejících dokonalou dovolenou bez starostí. Založeno na vašich požadavcích a preferencích, okořeněno mými zkušenostmi a špetkou lásky k cestování. Tak kam to bude? </Perex>
            <CtaLink href="https://docs.google.com/forms/d/e/1FAIpQLSexF3RsjZwtwM1USCIFolI4eFVhTI_ByGMGcPwNfSOW4Cj8Cw/viewform?usp=pp_url">Nezávazná objednávka</CtaLink>
          </TitleWithCta>

        <Bundles>
          {bundles.map(({index, title, icon, duration, price }) =>
            <li key={index}>
              <Card title={title} icon={icon} duration={duration} price={price} bgImage/>
            </li>)
          }
        </Bundles>
         <BundleInfo>
             <DescriptionTitle>V itineráři najdete:</DescriptionTitle>
             <BundleList>
              {bundleIncludes.map(({index, icon, text}) =>
               <BundleListItem key={index}>
                  <span className={icon}></span>
                  <BundleListText>{text}</BundleListText>
                </BundleListItem>
              )}
            </BundleList>
            <BundleNote>Ke všemu získáváte odkazy – stačí pouze kliknout a provést/dokončit rezervaci.</BundleNote>
         </BundleInfo>
         <Instructions>Itinerář poptávejte prostřednictvím <a href="https://docs.google.com/forms/d/e/1FAIpQLSexF3RsjZwtwM1USCIFolI4eFVhTI_ByGMGcPwNfSOW4Cj8Cw/viewform?usp=pp_url">nezávazného objednávkového formuláře</a>, případně využijte některý z kontaktů <Link to="/kontakt">zde</Link>.</Instructions>
    </Wrapper>
    )
}


export default ServiceBundles